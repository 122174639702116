<template>
  <div class="fluid">
    <div class="tree">
      <NestedDraggable :items="sectionsTreeFilled" :disabled="isPreviousCycle"/>
    </div>
    <div class="grid mt-2">
      <Button icon="pi pi-plus" :label="$t('menus.section.new')" class="p-button-outlined" @click="showAddSectionDialog = true" :disabled="isPreviousCycle" />
    </div>
    <DialogAddSection @sectionAdded="addSection" v-model="showAddSectionDialog" />
  </div>
</template>

<script>
import { ref, computed, watch, inject, onMounted } from 'vue'
import { useFlowEditor, useFlowPMMenuItemBuilder } from '@/compositions'
import DialogAddSection from './DialogAddSection.vue'
import NestedDraggable from './NestedDraggable.vue'

// To convert merged tree into sectionsTree
const extractMapping = (tree) => {
  return tree ? tree.map((item) => {
    return {
      id: item.doc.id,
      items: item.items && item.items.length ? extractMapping(item.items) : []
    }
  }) : null
}

export default {
  props: { editorKey: String, keyVal: Number },
  components: {
    DialogAddSection,
    NestedDraggable,
  },
  setup(props, {emit}) {
    const isPreviousCycle = inject('isPreviousCycle')

    // To merge sections & sectionsTree
    const makeMapping = (tree, data) => {
      return tree ? tree.map((item) => {
        return {
          ...useFlowPMMenuItemBuilder({
            propDoc: data[item.id],
            editorMain: props.editorKey,
            onFieldChange: () => {
              // DO NOTHING
            },
            onUpdateChange: () => {
              // DO NOTHING
            }
          }),
          items: makeMapping(item.items, data),
        }
      }) : []
    }
    const editSection = inject('editSection')
    const { fieldGet, fieldSet, ...flowEditorAPI } = useFlowEditor(props.editorKey)
    const sections = computed({
      get: () => fieldGet('cycle.sections'),
      set: (value) => {
        fieldSet({field: 'cycle.sections', value})
      }
    })
    const sectionsTree = computed({
      get: () => fieldGet('cycle.sectionsTree'),
      set: (value) => {
        fieldSet({field: 'cycle.sectionsTree', value})
      }
    })
    const recalculateTree = () => (
      JSON.parse(JSON.stringify(
        makeMapping(sectionsTree.value, sections.value)
      ))
    )
    const sectionsTreeFilled = ref(recalculateTree())
    watch(sectionsTreeFilled, (newValue) => {
      fieldSet({field: 'cycle.sectionsTree', value: extractMapping(newValue)})
    }, { deep: true })

    watch(() => props.keyVal, () => {
      sectionsTreeFilled.value = recalculateTree()
    })

    onMounted(() => emit('rendered'))

    return {
      isPreviousCycle,
      editSection,
      sectionsTreeFilled,
      showAddSectionDialog: ref(false),
      fieldGet,
      fieldSet,
      ...flowEditorAPI
    }
  },
  methods: {
    addSection (section) {
      this.fieldSet({ field: `cycle.sections.${section.id}`, value: section })
      this.fieldPush({
        field: 'cycle.sectionsTree',
        value: {
          id: section.id,
          items: []
        }
      })
      this.sectionsTreeFilled.push({
        ...useFlowPMMenuItemBuilder({
          propDoc: section,
          editorMain: this.editorKey,
          onFieldChange: () => {
            // DO NOTHING
          },
          onUpdateChange: () => {
            // DO NOTHING
          }
        }),
        items: []
      })
      this.editSection({
        ...section,
        items: []
      })
    },
  }
}
</script>

